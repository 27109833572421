import { dateObjectToIso, dateIsoToObject } from './date';
import config from './load-config';

const grace = config.checkout.timedOutProductGracePeriodMinutes;
const graceMilliseconds = grace * 60000;

const checkoutGracePeriod = (to: string) =>
  dateObjectToIso(new Date(dateIsoToObject(to).getTime() + graceMilliseconds));

export default checkoutGracePeriod;
