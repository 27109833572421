import { Provider as StoreProvider } from 'react-redux';
import AppContext, {
  AppNotification,
  VodapayContext,
  VodapayEventListener,
  defaultVodapayContext,
} from 'contexts/app';
import AppNotifications from 'components/_global/app-notifications';
import CartInspector from 'components/_global/cart-inspector';
import NavigationBar from 'components/_global/navigation-bar';
import Layout from 'components/_global/layout';
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
// TODO: re-enable once notification is up again
// import NotificationSocketListener from 'components/_global/notification-socket-listener';
import OnlineStatusListener from 'components/_global/online-status-listener';
import {
  VodapayLoader,
  VodapayApi,
  VodapayListener,
  VODAPAY_CLIENT_NAME,
} from 'components/_global/vodapay';
import store from '../redux/store';
import anotherdayonlyTheme from 'themes/anotherdayonly';
import onedayonlyTheme from 'themes/onedayonly';
import onenightonlyTheme from 'themes/onenightonly';
import clearancesaleTheme from 'themes/clearancesale';
import everydayessentialsTheme from 'themes/everydayessentials';
import extratimedealsTheme from 'themes/extratimedeals';
import giftvouchersTheme from 'themes/giftvouchers';
import { AppProps } from 'next/app';
import { Global, css, ThemeProvider } from '@emotion/react';
import { BASE_FONT_SIZE } from 'themes/onedayonly';
import { fontFaces, visibility } from 'components/_global/styles';
import 'what-input';
import {
  useApollo,
  ApolloClientOptions,
  WEB_CLIENT_NAME,
} from '../lib/apollo-client';
import { ApolloProvider } from '@apollo/client';
import Script from 'next/script';
import { StickyMessageInterface, PageLayoutProps } from 'types/types';
import ErrorBoundary from 'components/_global/error-boundary';
import BroadcastChannelReceiver from 'components/_global/broadcast-channel/receiver';
import { canonicalUrl } from '../util/url';
import { getCookie } from '../util/cookies';
import { useGoogleTagInfo } from 'hooks/customer';

const themes = {
  anotherdayonly: anotherdayonlyTheme,
  clearancesale: clearancesaleTheme,
  onedayonly: onedayonlyTheme,
  onenightonly: onenightonlyTheme,
  everydayessentials: everydayessentialsTheme,
  giftvouchers: giftvouchersTheme,
  extratimedeals: extratimedealsTheme,
};

type AppInnerProps = Pick<AppProps, 'Component'> &
  Pick<AppProps, 'pageProps'> & {
    themeName: string | null;
    messages?: StickyMessageInterface[];
    isAnotherDayOnlyActive?: boolean;
    showNavigation?: boolean;
    layoutProps: PageLayoutProps;
  };

const GtmInfoLoader = memo(
  ({
    setClientOptions,
  }: {
    setClientOptions: Dispatch<SetStateAction<ApolloClientOptions>>;
  }) => {
    const { gtagIsInitialized } = useContext(AppContext);
    const gtm = useGoogleTagInfo(gtagIsInitialized);

    useEffect(() => {
      if (!gtm.clientId || !gtm.sessionId) return;
      setClientOptions(options => ({ ...options, gtm }));
    }, [gtm, setClientOptions]);

    return null;
  }
);

const AppInner = ({
  Component,
  pageProps,
  themeName,
  messages,
  isAnotherDayOnlyActive,
  showNavigation,
  layoutProps,
}: AppInnerProps) => {
  let theme = themeName;
  if (!theme) {
    if (isAnotherDayOnlyActive) {
      theme = 'anotherdayonly';
    } else {
      theme = 'onedayonly';
    }
  }

  return (
    <ThemeProvider theme={themes[theme]}>
      <Global
        styles={theme => css`
          * {
            box-sizing: border-box;
          }

          [data-whatintent='mouse'] *:focus,
          [data-whatintent='touch'] *:focus {
            outline: none;
          }

          html {
            ${fontFaces};
            font-size: ${BASE_FONT_SIZE}px;
            @media ${theme.mediaQueries.mobileOnly} {
              font-size: 16px;
            }
          }

          html {
            background-color: white;
            font-weight: 400;
            line-height: 1;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
          }

          body {
            background-color: white;
            color: ${theme.colors.black};
            margin: 0;
            padding: 0;
            overflow-x: hidden;
          }

          body,
          input,
          select,
          textarea {
            font-family: ${theme.fonts.body};
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: 0;
          }

          a {
            color: currentColor;
            text-decoration: none;
          }

          p {
            margin: 0;
          }

          dl,
          ol,
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
          }

          dd,
          dt {
            margin: 0;
          }

          button {
            background: none;
            border: 0;
            cursor: pointer;
            padding: 0;
            touch-action: manipulation;
            user-select: none;
            -webkit-appearance: none;
          }

          .noscroll {
            overflow: hidden;
          }

          .grecaptcha-badge {
            visibility: hidden;
          }
          h4 {
            margin: 1em 0;
          }

          ${visibility(theme)};
        `}
      />
      {/* <NotificationSocketListener errorHandler={error => logError(error)} /> */}
      <OnlineStatusListener />
      <AppNotifications />
      <Layout messages={messages} {...layoutProps}>
        <Component {...pageProps} />
      </Layout>
      <CartInspector />
      <BroadcastChannelReceiver />
      {showNavigation && <NavigationBar />}
    </ThemeProvider>
  );
};

const SailthruHidLoader = ({
  setClientOptions,
}: {
  setClientOptions: Dispatch<SetStateAction<ApolloClientOptions>>;
}) => {
  useEffect(() => {
    const sailthruHid = getCookie('sailthru_hid');
    if (sailthruHid) {
      setClientOptions(options => ({ ...options, sailthruHid }));
    }
  }, [setClientOptions]);
  return null;
};

const ODOApp = ({ router: { asPath, isReady }, ...restProps }: AppProps) => {
  const [themeName, setThemeName] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<AppNotification[]>([]);
  const [globalStatuses, setGlobalStatuses] = useState<string[]>([]);
  const [gtagIsInitialized, setGtagIsInitialized] = useState(false);

  const [isNavigationBarActive, setNavigationBarActive] = useState(false);

  const [vodapay, setVodapay] = useState<VodapayContext>(defaultVodapayContext);
  const [vodapayApi, setVodapayApi] = useState<VodapayApi | undefined>(
    undefined
  );
  const [vodapayEventListeners, setVodapayEventListeners] = useState<
    VodapayEventListener[]
  >([]);

  const [clientOptions, setClientOptions] = useState<ApolloClientOptions>({
    clientName: WEB_CLIENT_NAME,
  });

  const client = useApollo(clientOptions);

  useEffect(() => {
    if (vodapay.isActive) {
      setClientOptions(options => ({
        ...options,
        clientName: VODAPAY_CLIENT_NAME,
      }));
      setNavigationBarActive(true);
    }
  }, [vodapay.isActive]);

  /**
   * NOTE: We're disabling datadog RUM for now due to costing.
   * Instead of removing it entirely, I'm commenting it out for now.
   *
   * TODO: Once final decisions have been made we can either remove all DD code or re-enable it.
   */
  // useEffect(() => {
  //   import('../util/datadog-error-logger')
  //     .then(({ datadogInit }) => datadogInit())
  //     .catch();
  // }, []);

  const messages = restProps?.pageProps?.meta?.messages || undefined;
  const themeOverride = restProps?.pageProps?.meta?.theme || undefined;
  const isAnotherDayOnlyActive =
    restProps?.pageProps?.meta?.isAnotherDayOnlyActive || undefined;

  const layoutProps: PageLayoutProps = {
    showAdoHeader: !!isAnotherDayOnlyActive,
    ...(restProps?.pageProps?.layout || {}),
    ...(isNavigationBarActive ? { isNavigationBarActive: true } : {}),
  };

  // check if we already have a canonical tag defined,
  // else try and generate one (but opt to do nothing rather if there are ANY issues)
  if (!layoutProps.metaTags?.canonical && isReady && asPath) {
    const canonical = canonicalUrl(asPath);
    if (canonical) {
      layoutProps.metaTags = { ...layoutProps.metaTags, canonical };
    }
  }

  return (
    <ErrorBoundary>
      <AppContext.Provider
        value={{
          themeName,
          setThemeName,
          notifications,
          setNotifications,
          vodapay,
          vodapayEventListeners,
          setVodapayEventListeners,
          isNavigationBarActive,
          globalStatuses,
          setGlobalStatuses,
          gtagIsInitialized,
          setGtagIsInitialized,
        }}
      >
        <Script
          id="browser-check"
          src="/browser-check.js"
          strategy="lazyOnload"
        />

        <VodapayLoader setVodapay={setVodapay} setVodapayApi={setVodapayApi} />
        <SailthruHidLoader setClientOptions={setClientOptions} />
        <ApolloProvider client={client}>
          <StoreProvider store={store}>
            <AppInner
              {...restProps}
              themeName={themeOverride || themeName}
              messages={messages}
              isAnotherDayOnlyActive={isAnotherDayOnlyActive}
              showNavigation={isNavigationBarActive}
              layoutProps={layoutProps}
            />
            <GtmInfoLoader setClientOptions={setClientOptions} />

            {!!vodapayApi && (
              <VodapayListener
                api={vodapayApi}
                setVodapay={setVodapay}
                vodapayEventListeners={vodapayEventListeners}
              />
            )}
          </StoreProvider>
        </ApolloProvider>
      </AppContext.Provider>
    </ErrorBoundary>
  );
};

export default ODOApp;
