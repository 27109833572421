import { css, Theme } from '@emotion/react';
import NewsletterModal from 'components/_global/footer/modal/newsletter-signup';
import { Box, Container, Flex, Grid } from 'components/box';
import { Link } from 'components/configurable-routing';
import NewsletterFooter from 'components/newsletter/footer';
import { Heading, Text } from 'components/typography';
import withConfig from 'components/with-config';
import { LayoutContext } from 'contexts/layout';
import { useVodapayActive } from 'hooks/app';
import { useRouter } from 'next/router';
import { memo, useContext } from 'react';
import { Config, PaymentMethodConfig } from 'types/interface';
import { Category, PermanentShopEnum } from 'types/types';
import asTitleCase from '../../../util/as-title-case';
import config from '../../../util/load-config';
import resourceUrl from '../../../util/make-url';
import { ReactGaTrackCategory, track } from '../../../util/track';
import FacebookIcon from './social-media/facebook.svg';
import InstagramIcon from './social-media/instagram.svg';
import TwitterXIcon from './social-media/twitterx.svg';
import Button from 'components/button';

const socialMediaCss = (theme: Theme) => css`
  :before {
    content: '';
    float: left;
    padding-top: 100%;
  }
  :hover {
    background-color: ${theme.colors.black};
  }
`;

const SocialMediaIcons = () => (
  <Grid
    gridTemplateColumns="repeat(3, 1fr)"
    gridColumnGap="2px"
    maxHeight="40px"
    mx={['1px', 0]}
    mb={['3px', 0]}
    width={['100%', '122px']}
  >
    <Link
      href={config.social.twitterPageUrl}
      target="_blank"
      title="Follow us on Twitter"
      prefetch={false}
    >
      <Flex
        backgroundColor="blacker"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="relative"
        css={socialMediaCss}
      >
        <TwitterXIcon />
      </Flex>
    </Link>

    <Link
      href={config.social.facebookPageUrl}
      target="_blank"
      title="Follow us on Facebook"
      prefetch={false}
    >
      <Flex
        backgroundColor="blacker"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="relative"
        css={socialMediaCss}
      >
        <FacebookIcon />
      </Flex>
    </Link>

    <Link
      href={config.social.instagramUrl}
      target="_blank"
      title="Follow us on Instagram"
      prefetch={false}
    >
      <Flex
        backgroundColor="blacker"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="relative"
        css={socialMediaCss}
      >
        <InstagramIcon />
      </Flex>
    </Link>
  </Grid>
);

const defaultPaymentMethod: PaymentMethodConfig = {
  enabled: false,
};

const Footer = memo(
  ({
    categories,
    config: {
      build: {
        meta: { version },
      },
      checkout: {
        paymentMethods: {
          visa: { enabled: visaEnabled } = defaultPaymentMethod,
          mastercard: { enabled: mastercardEnabled } = defaultPaymentMethod,
          eft: { enabled: eftEnabled } = defaultPaymentMethod,
          snapscan: { enabled: snapscanEnabled } = defaultPaymentMethod,
          ipay: { enabled: ipayEnabled } = defaultPaymentMethod,
          ebucks: { enabled: ebucksEnabled } = defaultPaymentMethod,
          discovery: { enabled: discoveryEnabled } = defaultPaymentMethod,
          amex: { enabled: amexEnabled } = defaultPaymentMethod,
        },
      },
    },
  }: {
    categories?: Category[];
    config: Config;
  }) => {
    //TODO: Add link to saved deals once FE-204 is merged to dev.
    const vodapayIsActive = useVodapayActive();

    const {
      pathname,
      query: { when: date },
    } = useRouter();

    const showNewsletter =
      pathname === '/' ||
      pathname === '/vodapay' ||
      pathname === '/clearance-sale' ||
      pathname === '/vodapay-clearance-sale' ||
      pathname.indexOf('/products/') === 0 ||
      pathname.indexOf('/category/') === 0;

    const { newsletterModalIsOpen, setNewsletterModalIsOpen } = useContext(
      LayoutContext
    );

    return (
      <footer
        css={theme => css`
          background-color: ${theme.colors.blacker};
          border-top: 1px solid ${theme.colors.mediumGrey};
          color: #e5e5e5;
          font-family: ${theme.fonts.body};
          overflow: hidden;
          line-height: 21px;
          flex-shrink: 0;
        `}
      >
        <NewsletterModal
          close={() => setNewsletterModalIsOpen(false)}
          isOpen={newsletterModalIsOpen}
        />
        {showNewsletter && (
          <NewsletterFooter
            title="want eeeeven more deals?"
            subtitle="sign up for the newsletter for less scrolling and more loling!"
          />
        )}

        <Container py={4}>
          <Grid
            gridTemplateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            gridAutoFlow="row"
          >
            <Flex flexDirection="column" alignItems="flex-start">
              <Heading fontSize={2} color="white" fontWeight={900}>
                About Us
              </Heading>
              <Link
                href={config.odoBlog}
                target="_blank"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Our Blog
              </Link>
              <Link
                href="/about-us"
                color="white"
                fontFamily="header"
                prefetch={false}
                fontWeight={500}
                fontSize={2}
              >
                About ODO
              </Link>
              <Link
                href="/sell-with-us"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Sell with Us
              </Link>
              <Link
                href={config.careersPage}
                target="_blank"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Careers
              </Link>
              <Link
                href={config.helpCentreLinks.termsAndConditions}
                target="_blank"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Terms & Conditions
              </Link>
              <Link
                href={config.helpCentreLinks.privacyPolicy}
                target="_blank"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Privacy Pledge
              </Link>
            </Flex>

            <Flex flexDirection="column" alignItems="flex-start">
              <Heading fontSize={2} color="white" fontWeight={900}>
                About You
              </Heading>
              <Link
                href="/my-orders"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Track My Order
              </Link>

              <Link
                href="/my-account"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                My Account
              </Link>

              <Link
                href="/my-orders"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                My Orders
              </Link>

              <Link
                href="/my-orders"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Log a Return
              </Link>

              <Link
                target="_blank"
                href={config.helpCentreLinks.reportBugNewFeature}
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Report Bug/New Feature
              </Link>

              <Button
                variant="minimal"
                onClick={() => setNewsletterModalIsOpen(true)}
                color="white"
                hoverColor="none"
                textTransform="none"
                fontWeight={500}
                fontSize={2}
                css={theme => css`
                  font-family: ${theme.fonts.header};
                `}
              >
                Subscribe
              </Button>
            </Flex>

            <Flex flexDirection="column" alignItems="flex-start">
              <Heading fontSize={2} color="white" fontWeight={900}>
                The Important Stuff
              </Heading>
              <Link
                target="_blank"
                href={config.helpCentreLinks.customerService}
                color="white"
                fontWeight={500}
                fontFamily="header"
                prefetch={false}
                fontSize={2}
              >
                Help Centre
              </Link>
              {!vodapayIsActive && (
                <Link
                  target="_blank"
                  href={config.helpCentreLinks.bankingDetails}
                  color="white"
                  fontWeight={500}
                  fontFamily="header"
                  prefetch={false}
                  fontSize={2}
                >
                  Our Banking Details
                </Link>
              )}
              <Link
                target="_blank"
                href={config.helpCentreLinks.competitions}
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Competitions
              </Link>
              <Link
                target="_blank"
                href={config.helpCentreLinks.aboutReturns}
                color="white"
                fontWeight={500}
                fontFamily="header"
                prefetch={false}
                role="button"
                tabIndex={-5}
                onKeyDown={() =>
                  track('Clicked Returns Link', ReactGaTrackCategory.footer)
                }
                onClick={() =>
                  track('Clicked Returns Link', ReactGaTrackCategory.footer)
                }
                fontSize={2}
              >
                Returns
              </Link>
              <Link
                target="_blank"
                href={config.helpCentreLinks.contactUs}
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Contact Us
              </Link>
            </Flex>

            <Flex flexDirection="column" alignItems="flex-start">
              <Heading fontSize={2} color="white" fontWeight={900}>
                Deals
              </Heading>
              <Link
                href="/"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Today&apos;s Deals
              </Link>
              <Link
                href="/shops/extra-time-deals"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Extra Time Deals
              </Link>
              <Link
                href="/clearance-sale"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Clearance Deals
              </Link>
              <Link
                href={`/shops/${PermanentShopEnum.EVERYDAY_ESSENTIALS}`}
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Everyday Essentials
              </Link>
              <Link
                href="/gift-vouchers"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Gift Vouchers
              </Link>
              <Link
                href="/black-friday"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Black Friday
              </Link>
              <Link
                href="/brands"
                color="white"
                fontFamily="header"
                fontWeight={500}
                prefetch={false}
                fontSize={2}
              >
                Brands
              </Link>
            </Flex>
          </Grid>
        </Container>
        {categories && (
          <Box borderTop="1px solid" borderTopColor="footerDark">
            <Container py={3} textAlign="center">
              {categories.map((category, idx) => (
                <Link
                  key={category.id}
                  dynamicUrl="/category/[id]"
                  href={`/category/${category.id}${
                    date ? `/?when=${date}` : ''
                  }`}
                  color="lightGrey"
                  fontWeight="light"
                  fontSize={1}
                >
                  {`${asTitleCase(category.name)}${
                    idx < categories.length - 1 ? ' \\ ' : ''
                  }`}
                </Link>
              ))}
            </Container>
          </Box>
        )}

        {!vodapayIsActive && (
          <Flex backgroundColor="footerDark">
            <Container py={3}>
              <Grid
                gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
                lineHeight="0"
              >
                <Flex
                  flexDirection="row"
                  justifyContent={['center', 'flex-start']}
                  alignItems={['flex-start', 'center']}
                  width={['100%', null, '80%']}
                >
                  <Link
                    href={config.mobileAppLinks.iOS}
                    prefetch={false}
                    target="_blank"
                  >
                    <img
                      src={resourceUrl('odo/app-store.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      alt="Available on the App Store"
                      css={css`
                        width: inherit;
                        max-height: 35px;
                      `}
                    />
                  </Link>
                  <Flex flexDirection="column" mx={2}>
                    <Link
                      href={config.mobileAppLinks.android.footer}
                      prefetch={false}
                      target="_blank"
                      css={css`
                        display: flex;
                        justify-content: center;
                      `}
                    >
                      <img
                        src={resourceUrl('odo/play-store.png', {
                          width: 200,
                          isAssetUrl: true,
                        })}
                        alt="Get it on Google Play"
                        css={css`
                          width: inherit;
                          max-height: 35px;
                        `}
                      />
                    </Link>
                    <Flex mt={1} className="show-for-mobile-only">
                      <SocialMediaIcons />
                    </Flex>
                  </Flex>
                  <Link
                    href={config.mobileAppLinks.huawei}
                    prefetch={false}
                    target="_blank"
                  >
                    <img
                      src={resourceUrl('odo/app-gallery.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      alt="Explore it on AppGallery"
                      css={css`
                        width: inherit;
                        max-height: 35px;
                      `}
                    />
                  </Link>
                </Flex>
                <Flex
                  justifyContent="flex-end"
                  alignItems="center"
                  className="hide-for-mobile-only"
                >
                  <SocialMediaIcons />
                </Flex>
              </Grid>
            </Container>
          </Flex>
        )}
        <Container py={0}>
          <Flex
            pb={[2, null, 0]}
            width="100%"
            fontSize="s"
            flexDirection={['column', null, 'row']}
            justifyContent={['center', null, 'space-between']}
            alignItems="center"
            css={css`
              border-bottom: 1px solid #939393;
            `}
          >
            <Flex
              flex={0}
              mb={3}
              alignItems="center"
              justifyContent={['center', null, 'flex-start']}
              flexWrap={['wrap', null, 'nowrap']}
              flexDirection="row"
              css={theme => css`
                white-space: nowrap;
                img {
                  display: inline-block;
                  opacity: 0.5;
                  margin-right: ${theme.space[4]}px;
                  margin-top: ${theme.space[3]}px;
                }
              `}
            >
              {!vodapayIsActive && (
                <>
                  {visaEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/visa.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 14px;
                        max-width: 50px;
                        opacity: 0.5;
                      `}
                      alt="Visa logo"
                    />
                  )}

                  {mastercardEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/mastercard.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 22px;
                        max-width: 50px;
                        opacity: 0.5;
                      `}
                      alt="Mastercard logo"
                    />
                  )}

                  {eftEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/eft.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 14px;
                        max-width: 50px;
                        margin-right: 24px !important;
                        opacity: 0.5;
                      `}
                      alt="EFT"
                    />
                  )}

                  {snapscanEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/snapscan.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 25px;
                        max-width: 80px;
                        margin-right: 24px !important;
                        opacity: 0.5;
                      `}
                      alt="SnapScan logo"
                    />
                  )}

                  <img
                    src={resourceUrl('odo/footer-imgs/zapper.png', {
                      width: 200,
                      isAssetUrl: true,
                    })}
                    css={css`
                      max-height: 15px;
                      max-width: 50px;
                      margin-right: 24px !important;
                      opacity: 0.5;
                      margin-bottom: -5px;
                    `}
                    alt="Zapper logo"
                  />

                  {ipayEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/ozow.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      alt="Ozow logo"
                      css={css`
                        max-height: 18px;
                        max-width: 50px;
                        opacity: 0.5;
                        margin-bottom: -4px;
                      `}
                    />
                  )}

                  {ebucksEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/ebucks.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 23px;
                        max-width: 50px;
                        margin-bottom: -3px;
                        opacity: 0.5;
                        margin-right: 29px;
                      `}
                      alt="eBucks logo"
                    />
                  )}

                  <img
                    src={resourceUrl('odo/footer-imgs/mobicred.png', {
                      width: 200,
                      isAssetUrl: true,
                    })}
                    alt="Mobicred logo"
                    css={css`
                      max-height: 14px;
                      max-width: 50px;
                      opacity: 0.5;
                      margin-right: 24px !important;
                    `}
                  />

                  {discoveryEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/discovery.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 16px;
                        max-width: 50px;
                        opacity: 0.5;
                      `}
                      alt="Discovery Miles logo"
                    />
                  )}

                  {amexEnabled && (
                    <img
                      src={resourceUrl('odo/footer-imgs/amex-2024.png', {
                        width: 200,
                        isAssetUrl: true,
                      })}
                      css={css`
                        max-height: 24px;
                        max-width: 55px;
                        opacity: 0.5;
                      `}
                      alt="American Express logo"
                    />
                  )}

                  <img
                    src={resourceUrl('odo/footer-imgs/payflex-large.png', {
                      width: 200,
                      isAssetUrl: true,
                    })}
                    alt="PayFlex Logo"
                    css={css`
                      max-height: 20px;
                      max-width: 55px;
                      opacity: 0.5;
                    `}
                  />
                  {/**
                   * Commenting out the below snippet in case business change their mind.
                   */}
                  {/* <img
                  src={resourceUrl('odo/footer-imgs/1voucher.png', {
                    width: 200,
                    isAssetUrl: true,
                  })}
                  alt="1Voucher Logo"
                  css={css`
                    max-height: 24px;
                    max-width: 55px;
                    opacity: 0.5;
                  `}
                /> */}
                </>
              )}
            </Flex>
            <Box
              flex="0"
              my="2"
              css={css`
                white-space: nowrap;
                img {
                  display: inline-block;
                  opacity: 0.5;
                  max-height: 20px;
                }
              `}
            >
              <a
                href="https://monitor2004.sucuri.net/m/verify/?r=4bdc455ed4189a6caaca3b50ab729d6df231dc0cd7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={resourceUrl('odo/footer-imgs/godaddy.png', {
                    width: 400,
                    isAssetUrl: true,
                  })}
                  alt="GoDaddy verified and secured"
                  css={css`
                    max-height: 28px !important;
                    opacity: 0.5;
                    :hover {
                      opacity: 1 !important;
                    }
                  `}
                />
              </a>
            </Box>
          </Flex>
        </Container>
        <Container py={0}>
          <Flex
            width="100%"
            fontSize="s"
            flexDirection={['column', null, 'row']}
            justifyContent={['center', null, 'space-between']}
            alignItems="center"
            my={3}
            color="#939393"
          >
            <Box>
              <Link
                target="_blank"
                href={config.helpCentreLinks.termsAndConditions}
                fontWeight={500}
                prefetch={false}
                css={css`
                  color: #939393;
                  :hover {
                    color: white !important;
                  }
                `}
              >
                Terms and Conditions
              </Link>
              &nbsp;|&nbsp;
              <Link
                target="_blank"
                href={config.helpCentreLinks.privacyPolicy}
                fontWeight={500}
                prefetch={false}
                css={css`
                  color: #939393;
                  :hover {
                    color: white !important;
                  }
                `}
              >
                Privacy Policy
              </Link>
            </Box>
            <Box>
              <Text lineHeight="1.3em" textAlign="center">
                &copy; 2009 - {new Date().getFullYear()} OneDayOnly Offers (Pty)
                Ltd. All rights reserved
                {version && <> - v{version}</>}
              </Text>
            </Box>
          </Flex>
        </Container>
      </footer>
    );
  }
);

export default withConfig(Footer);
