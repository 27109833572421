export enum HeaderDesignEnum {
  Sleek = 'sleek',
  Chunky = 'chunky',
}

/**
 * Put and export your interfaces here
 */
export interface MetaTag {
  keywords?: string;
  description?: string;
  title?: string;
}

export interface SeoMeta {
  description: string;
  siteName: string;
  siteTitle: string;
  pages: Record<string, MetaTag>;
}

export interface SeoParameters {
  minTitleLength: number;
  maxTitleLength: number;
}

export interface StaticContentBlock {
  title?: string;
  titleColor?: string;
  panelColor?: string;
  copy?: string;
}

export interface StaticContent {
  slug: string;
  contentTitle?: string;
  meta?: MetaTag;
  header?: StaticContentBlock;
  body?: StaticContentBlock[][];
}

export interface CheckoutSettings {
  customerServiceWhatsAppLink: string;
  timedOutProductGracePeriodMinutes: number;
  allowGuest: boolean;
  paymentMethods: PaymentMethodSettings;
}

interface RoutingOverride {
  regex: RegExp;
  type: 'client' | 'server';
}

export interface BuildConfig {
  showVersionInMenu: boolean;
  meta: {
    version: string | null;
    timestamp: string | null;
  };
}

export interface RoutingConfig {
  default: 'client' | 'server';
  overrides?: RoutingOverride[];
}

export enum AlgoliaIndexEnum {
  Homepage = 'homepage',
  Clearance = 'clearance',
  EverydayEssentials = 'shops/everyday-essentials',
  ExtraTimeDeals = 'shops/extra-time-deals',
}

export interface AlgoliaConfig {
  appId: string;
  apiKey: string;
  insightsUrl: string;
  paramIndexName: string;
  paramQueryIdName: string;
  indices: Record<AlgoliaIndexEnum, string | null>;
}

export interface MagentoConfig {
  uri: string;
}

interface SailthruConfig {
  personalizeId: string;
  customerId: string;
}

interface PudoConfig {
  url: string;
}

interface BrandPageFallbacks {
  fallbackTitle: string;
  fallbackDescription: string;
  fallbackHeading: string;
  fallbackKeywords: string;
  fallbackContent: string;
}

export interface Config {
  brandPageFallbacks: BrandPageFallbacks;
  build: BuildConfig;
  routing: RoutingConfig;
  seo: SeoTag;
  social: SocialLink;
  security: SecuritySettings;
  sailthru: SailthruConfig;
  products: ProductSettings;
  formatting: FormattingSettings;
  accounts: AccountSettings;
  checkout: CheckoutSettings;
  store: StoreSettings;
  site: SiteSettings;
  pargo: PargoConfig;
  pudo: PudoConfig;
  notifications: NotificationSettings;
  recaptcha: RecaptchaSettings;
  zendesk: ZendeskChatSettings;
  analytics: GoogleAnalyticsSettings;
  gtm: GoogleTagManagerSettings;
  gateway: GatewayConfig;
  magento: MagentoConfig;
  mobileAppLinks: mobileAppLinksConfig;
  assets: assetsConfig;
  helpCentreLinks: helpCentreLinksConfig;
  careersPage: string;
  odoBlog: string;
  attachments: AttachmentsSettings;
  banks: BankSettings[];
  datadog: DatadogConfig;
  return: ReturnsConfig;
  marketing: MarketingConfig;
  thirdParty: ThirdPartyConfig;
  algolia: AlgoliaConfig;
  headerTaglines: HeaderTaglinesConfig;
  featureFlags: FeatureFlagsConfig;
  growthbook: GrowthBookConfig;
  mixpanel: MixpanelConfig;
  buyersPortal: BuyersPortalConfig;
  mobicred: MobicredConfig;
  extraTimeDeals: ExtraTimeDealsConfig;
}

export interface GrowthBookConfig {
  cdnUrl: string;
  cdnKey: string;
}

export interface MixpanelConfig {
  productKey: string;
}

export interface FeatureFlagsConfig {
  headerDesign: HeaderDesignEnum;
}

export interface PargoConfig {
  url: string;
  token: string;
}

export interface CookieSettings {
  domain: string;
}

export interface PhoneNumberSettings {
  countryCode: string;
}

export interface ProductConditions {
  NEW: string;
  REFURBISHED: string;
  EX_DEMO_STOCK: string;
  DEMO_STOCK: string;
  SLIGHTLY_DAMAGED_PACKAGING: string;
  NO_PACKAGING: string;
  NO_TAGS: string;
}

export interface ProductLookupValues {
  conditions: ProductConditions;
}

export interface ProductSettings {
  values: ProductLookupValues;
  showDisclaimerQty: number;
  customerLimitDisclaimer: string;
}

export interface SecuritySettings {
  cookies: CookieSettings;
}

export interface SeoTag {
  meta: SeoMeta;
  parameters: SeoParameters;
  noIndexCuttOffDate: string;
}

export interface SocialLink {
  facebookPageUrl: string;
  facebookAppId: string;
  facebookAppVersion: string;
  instagramUrl: string;
  twitterPageUrl: string;
  twitterHandle: string;
  facebookFollowerCount: number;
  twitterFollowerCount: number;
  instagramFollowerCount: number;
  facebookDomainVerification: string;
  googleAppId: string;
}

export interface StatusFlag {
  enabled: boolean;
}

export interface FormattingSettings {
  phoneNumbers: PhoneNumberSettings;
}

export interface PasswordStrengthSettings {
  minLength: number;
  minCharacterClasses: number;
}

export interface SignUpSettings {
  passwordStrength: PasswordStrengthSettings;
}

export interface ResetPasswordSettings {
  autoLogin: boolean;
}

export interface AccountSettings {
  signup: SignUpSettings;
  resetPassword: ResetPasswordSettings;
}

export interface StoreSettings {
  name: string;
}

export interface SiteSettings {
  url: string;
}

export interface PaymentMethodConfig extends StatusFlag {
  proofOfPayment?: ProofOfPaymentConfig;
}

export interface PaymentMethodSettings {
  visa?: PaymentMethodConfig;
  mastercard?: PaymentMethodConfig;
  eft?: PaymentMethodConfig;
  snapscan?: PaymentMethodConfig;
  ipay?: PaymentMethodConfig;
  ebucks?: PaymentMethodConfig;
  discovery?: PaymentMethodConfig;
  amex?: PaymentMethodConfig;
}

export interface ProofOfPaymentConfig {
  email: string;
}

export interface RecaptchaSettings {
  key: string;
}

export interface ZendeskChatSettings {
  key: string;
}

export interface NotificationSettings {
  notificationEngineUri: string;
  notificationChannels: string;
  triggers: HashMap;
  lowStockWarningThreshold: number;
}

export interface GoogleAnalyticsSettings {
  trackingId: string;
}

export interface AttachmentsSettings {
  maxAttachmentsPerCategory: number;
  maxPostSizeBytes: number;
}

interface BankSettings {
  code: string;
  label: string;
}

export interface GoogleTagManagerSettings {
  containerId: string;
  dataLayerName: string;
  gtagTarget: string;
}

export interface GatewayConfig {
  uri: string;
}

export interface ThirdPartyConfig {
  preFetchDomains: string[];
}

export interface mobileAppLinksConfig {
  iOS: string;
  android: {
    footer: string;
    popup: string;
  };
  huawei: string;
}

export interface HeaderTaglinesConfig {
  'extra-time-deals': {
    header: string;
    text: string;
  };
  'everyday-essentials': {
    header: string;
    text: string;
  };
  'clearance-sale': {
    header: string;
    text: string;
  };
}

export interface helpCentreLinksConfig {
  customerService: string;
  termsAndConditions: string;
  blackFriday: string;
  privacyPolicy: string;
  aboutReturns: string;
  bankingDetails: string;
  competitions: string;
  faq: string;
  contactUs: string;
  orderPaidButCancelled: string;
  eftPayments: string;
  walletCredit: string;
  reportBugNewFeature: string;
}

export interface assetsConfig {
  baseUrl: string;
}

export interface HashMap {
  [key: string]: string | number;
}

export interface DatadogConfig {
  applicationId: string;
  clientToken: string;
  site: string;
  service?: string;
  env?: string;
  sampleRate?: number;
  trackInteractions?: boolean;
}

export interface BuyersPortalConfig {
  domain: string;
}

export interface MobicredConfig {
  applicationUrl: string;
}

export interface ReturnsConfig {
  reasonForReturn: {
    damaged: {
      code: string;
      label: string;
    };
    faulty: {
      code: string;
      label: string;
    };
    changedMind: {
      code: string;
      label: string;
    };
    exchange: {
      code: string;
      label: string;
    };
    wrongProduct: {
      code: string;
      label: string;
    };
    notAsPicturedOrDescribed: {
      code: string;
      label: string;
    };
    tooBig: {
      code: string;
      label: string;
    };
    tooSmall: {
      code: string;
      label: string;
    };
    productIncomplete: {
      code: string;
      label: string;
    };
  };
  maxUploadSize: number;
}

export interface MarketingConfig {
  soldOutOverlay: {
    showPromoDealOverlay: boolean;
    promoDealOverlayText: string;
    showPriceForPromoDeals: boolean;
    soldOutHeaders: string[];
    expiredHeaders: string[];
    promoDealHeaders: string[];
  };
  campaigns: {
    triswickSpinningWheelOnlinePayment: string;
    triswickSpinningWheelOfflinePayment: string;
  };
  appDownloadPopUp: {
    qrCodeLink: string;
  };
}

interface ExtraTimeDealsConfig {
  animationStartDate: string;
  animationEndDate: string;
}
