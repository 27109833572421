import { useState } from 'react';
import SearchWrapper from './search-wrapper';
import config from '../../../../util/load-config';
import { AlgoliaIndexEnum, HeaderDesignEnum } from 'types/interface';
import dynamic from 'next/dynamic';
import { TopBarProps } from './types';

const TopBarDesignSleek = dynamic(() => import('./design/sleek'));
const TopBarDesignChunky = dynamic(() => import('./design/chunky'));

const TopBar =
  config.featureFlags.headerDesign === HeaderDesignEnum.Sleek
    ? TopBarDesignSleek
    : TopBarDesignChunky;

const Wrapper = ({
  searchIndexName,
  ...restProps
}: TopBarProps & { searchIndexName?: AlgoliaIndexEnum }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const toggleSearch = () => setIsSearchActive(isActive => !isActive);

  const searchIndex =
    searchIndexName &&
    config.algolia.apiKey &&
    config.algolia.appId &&
    searchIndexName in config.algolia.indices &&
    config.algolia.indices[searchIndexName];

  return (
    <>
      {searchIndex ? (
        <SearchWrapper
          searchIndex={searchIndex}
          isSearchActive={isSearchActive}
          close={() => setIsSearchActive(false)}
        >
          <TopBar
            isSearchActive={isSearchActive}
            toggleSearch={toggleSearch}
            canSearch={true}
            {...restProps}
          />
        </SearchWrapper>
      ) : (
        <TopBar {...restProps} />
      )}
    </>
  );
};

export default Wrapper;
