import {
  useState,
  ReactNode,
  Fragment,
  useEffect,
  useRef,
  useContext,
} from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Header from 'components/_global/header';
import Footer from 'components/_global/footer';
import CookieConsent from 'components/_global/cookie-consent';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { LayoutContext, LayoutContextType } from 'contexts/layout';
import { RootState } from 'typesafe-actions';
import config from '../../../util/load-config';
import { Flex } from 'components/box';
import { StickyMessageInterface, PageLayoutProps } from 'types/types';
import {
  NAVIGATION_BAR_HEIGHT_MOBILE,
  NAVIGATION_BAR_HEIGHT_TABLET,
} from 'components/_global/navigation-bar';
import dynamic from 'next/dynamic';
import resourceUrl from '../../../util/make-url';
import { useVodapayActive } from 'hooks/app';
import ErrorBoundary from 'components/_global/error-boundary';
import { SailthruInit } from 'components/_global/sailthru';
import { ConvertExperiencesWidget } from 'components/_global/convert-experiences';
import { trackAlwaysOnDataLayer } from '../../../util/tag-manager';
import { useCustomer } from 'hooks/customer';
import MoreProductsFooter from 'components/_shared/widgets/more-products-footer';
import { CustomerState } from '../../../redux/customer/reducer';
import { PersistState } from 'redux-persist';
import { isPersistedState } from 'types/guards/redux';
import AppContext from 'contexts/app';

const useIsCustomerRehydrated = (): {
  rehydrated: boolean;
  timedOut: boolean;
} => {
  const [hasRehydrationTimedOut, setHasRehydrationTimedOut] = useState(false);
  const customer: CustomerState & {
    _persist?: PersistState | unknown;
  } = useSelector((state: RootState) => state.customer);
  const customerPersist = customer._persist;

  useEffect(() => {
    if (isPersistedState(customerPersist) && customerPersist.rehydrated) return;
    const timeoutId = setTimeout(() => {
      setHasRehydrationTimedOut(true);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [customerPersist]);

  const isPersisted = customerPersist && isPersistedState(customerPersist);

  return {
    rehydrated: isPersisted ? customerPersist.rehydrated : false,
    timedOut: hasRehydrationTimedOut,
  };
};

const SideDrawer = dynamic(() => import('components/_global/side-drawer'), {
  ssr: false,
});
const ListingWidgetsWrapper = dynamic(
  () => import('components/_global/layout/widgets/widget-wrapper'),
  { ssr: false }
);

const sharingImage = resourceUrl('odo/generic_sharing_image.png', {
  width: 600,
  isAssetUrl: true,
});

type LayoutProps = PageLayoutProps & {
  children?: ReactNode;
  messages?: StickyMessageInterface[];
};

const MetaAndLinks = ({
  metaTags = null,
  isVodapayActive,
}: {
  metaTags: LayoutProps['metaTags'];
  isVodapayActive?: boolean;
}) => (
  <Head>
    {config.thirdParty.preFetchDomains.map(domain => (
      <Fragment key={domain}>
        <link rel="dns-prefetch" href={domain} />
        <link rel="preconnect" href={domain} crossOrigin="anonymous" />
      </Fragment>
    ))}
    <title>{metaTags?.title || config.seo.meta.siteTitle}</title>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, minimum-scale=1"
    />
    {metaTags?.noIndex && <meta name="robots" content="noindex" />}
    {!isVodapayActive && (
      <meta name="apple-itunes-app" content="app-id=1530905791" />
    )}
    <meta name="msapplication-TileColor" content="#0093d0" />
    <meta name="theme-color" content="black" />
    <meta name="msapplication-TileImage" content="/144x144.png" />
    <link rel="icon" href="/favicon.ico" />
    <meta
      key="name.description"
      name="description"
      content={metaTags?.description || config.seo.meta.description}
    />
    <meta key="name.keywords" name="keywords" content={metaTags?.keywords} />
    <meta
      key="property.fb:app_id"
      property="fb:app_id"
      content={config.social.facebookAppId}
    />
    {!!config.social.facebookDomainVerification && (
      <meta
        name="facebook-domain-verification"
        content={config.social.facebookDomainVerification}
      />
    )}
    <meta
      key="property.og:description"
      property="og:description"
      content={metaTags?.description || config.seo.meta.description}
    />
    <meta key="property.og:image" property="og:image" content={sharingImage} />
    <meta
      key="property.og:site_name"
      property="og:site_name"
      content={config.seo.meta.siteName}
    />
    <meta
      key="property.og:title"
      property="og:title"
      content={config.seo.meta.siteTitle}
    />
    <meta
      key="property.og:url"
      property="og:url"
      content="https://www.onedayonly.co.za"
    />
    <meta
      key="property.twitter:card"
      property="twitter:card"
      content="summary_large_image"
    />
    <meta
      key="property.twitter:description"
      property="twitter:description"
      content={metaTags?.description || config.seo.meta.description}
    />
    <meta
      key="property.twitter:image:src"
      property="twitter:image:src"
      content={sharingImage}
    />
    <meta
      key="property.twitter:site"
      property="twitter:site"
      content={`@${config.social.twitterHandle}`}
    />
    <meta
      key="property.twitter:title"
      property="twitter:title"
      content={metaTags?.title || config.seo.meta.siteTitle}
    />

    {metaTags?.canonical && (
      <link key="canonical" rel="canonical" href={metaTags.canonical} />
    )}

    <link href="/manifest.json" rel="manifest" />
  </Head>
);

const Layout = ({
  children,
  messages,
  hideHeader,
  hideFooter,
  hideClock,
  showAdoHeader,
  isNavigationBarActive,
  metaTags = null,
  selectedTopTab = null,
  searchIndexName,
  headerTaglineText,
  headerTaglineTitle,
  gtmPageTitle,
  categories,
  hasScrollButton,
  hasAppDownloadWidget,
  disableHeaderShrinking,
  moreProductsFooter,
}: LayoutProps) => {
  const [newsletterModalIsOpen, setNewsletterModalIsOpen] = useState(false);

  const activeDrawer = useSelector(
    (state: RootState) => state.global.openDrawer
  );

  const LayoutContextValues: LayoutContextType = {
    newsletterModalIsOpen,
    setNewsletterModalIsOpen,
  };

  const isVodapayActive = useVodapayActive();
  const customer = useCustomer();
  const customerHydrationState = useIsCustomerRehydrated();
  const hasTrackedAlwaysOn = useRef(false);

  // Has to be done in the layout because we need access to the redux provider.
  useEffect(() => {
    if (hasTrackedAlwaysOn.current) return;
    if (customerHydrationState.rehydrated || customerHydrationState.timedOut) {
      trackAlwaysOnDataLayer({ user: customer, pageTitle: gtmPageTitle });
      hasTrackedAlwaysOn.current = true;
    }
  }, [customer, customerHydrationState, gtmPageTitle]);

  return (
    <LayoutContext.Provider value={LayoutContextValues}>
      <MetaAndLinks metaTags={metaTags} isVodapayActive={isVodapayActive} />

      {/* Overlay div when side drawer is open */}
      <div
        css={css`
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 280;
          cursor: pointer;
          transition: opacity 150ms ease-in-out;
          opacity: 0;
          pointer-events: none;
          ${activeDrawer &&
          css`
            opacity: 1;
            pointer-events: all;
          `}
        `}
      />

      <Flex
        minHeight="100vh"
        flexDirection="column"
        mb={
          isNavigationBarActive
            ? [
                `${NAVIGATION_BAR_HEIGHT_MOBILE}px`,
                `${NAVIGATION_BAR_HEIGHT_TABLET}px`,
              ]
            : undefined
        }
      >
        {!hideHeader && (
          <ErrorBoundary>
            <Header
              messages={messages}
              selectedTopTab={selectedTopTab}
              hideClock={hideClock}
              showAdoHeader={showAdoHeader}
              searchIndexName={searchIndexName}
              headerTaglineTitle={headerTaglineTitle}
              headerTaglineText={headerTaglineText}
              categories={categories}
              disableHeaderShrinking={disableHeaderShrinking}
            />
          </ErrorBoundary>
        )}

        <div
          css={css`
            flex: 1 0 auto;
          `}
        >
          {children}

          {(hasScrollButton || hasAppDownloadWidget) && (
            <ListingWidgetsWrapper
              hasScrollButton={hasScrollButton}
              hasAppDownloadWidget={hasAppDownloadWidget}
            />
          )}

          {moreProductsFooter?.enabled && (
            <MoreProductsFooter
              message={moreProductsFooter.message || 'Looking for more deals?'}
            />
          )}
        </div>

        {!hideFooter && (
          <ErrorBoundary>
            <Footer categories={categories} />
          </ErrorBoundary>
        )}

        {!hideHeader && (
          <ErrorBoundary>
            <SideDrawer />
          </ErrorBoundary>
        )}
      </Flex>
    </LayoutContext.Provider>
  );
};

const Wrapper = (props: LayoutProps) => {
  const { setGtagIsInitialized } = useContext(AppContext);
  return (
    <>
      {/* supposedly needs to be IMMEDIATELY after the body tag */}
      {!!config.gtm.containerId && (
        <noscript>
          <iframe
            title="gtm-noscript"
            src={`https://www.googletagmanager.com/ns.html?id=${config.gtm.containerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}

      <Layout {...props} />

      {/* nextjs will pick this up and decide when/where to put it in the header */}
      {!!config.gtm.containerId && (
        <>
          {/* data layer initializer (could be wrong, but pretty sure this needs to be present) */}
          {/* <Script id="gtm-data-layer" strategy="afterInteractive">
          {`window.${config.gtm.dataLayerName} = window.${config.gtm.dataLayerName} || [];`}
        </Script> */}

          {/* blocklist: for if any third-party scripts pulled via GTM break the site */}
          {/* <Script id="gtm-blocklist" strategy="afterInteractive">
          {`${config.gtm.dataLayerName}.push({'gtm.blocklist': ['nonGoogleScripts']});`}
        </Script> */}

          <Script id="gtm-sdk" strategy="beforeInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});` +
              `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';` +
              `j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;` +
              `f.parentNode.insertBefore(j,f);})` +
              `(window,document,'script','${config.gtm.dataLayerName}','${config.gtm.containerId}');`}
          </Script>
        </>
      )}
      {/**
       * @see https://developers.google.com/tag-platform/gtagjs/reference
       * @see https://developers.google.com/tag-platform/gtagjs/install
       */}
      {config.gtm.gtagTarget && (
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          onReady={() => {
            setGtagIsInitialized(true);
          }}
        >
          {`function gtag(){${config.gtm.dataLayerName}.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.gtm.gtagTarget}');`}
        </Script>
      )}

      <CookieConsent />
      <SailthruInit />
      {!props.disableConvertScript && <ConvertExperiencesWidget />}
    </>
  );
};

export default Wrapper;
