import {
  BundleProduct,
  ConfigurableProductAttributeOption,
  ConfigurableProduct,
  ConfigurableProductVariant,
  GroupedProduct,
  PhysicalProductInterface,
  ProductInterface,
  SimpleProduct,
  StockConfig,
  VirtualProduct,
} from 'types/types';
import mem from 'mem';
import { LinkProps } from 'components/configurable-routing';
import asTitleCase from './as-title-case';

const DEFAULT_MAX_QUANTITY = 10;

type Product =
  | BundleProduct
  | ConfigurableProduct
  | GroupedProduct
  | PhysicalProductInterface
  | ProductInterface
  | SimpleProduct
  | VirtualProduct;

export const isLimitedTimeDeal = (product: Product) => !!product?.campaign;

export const flattenVariantAttributes: (
  variant: ConfigurableProductVariant
) => Record<string, string> = mem(
  variant => {
    return variant.options.reduce(
      (attributes, attr: ConfigurableProductAttributeOption) => {
        attributes[attr.code] = attr.value;
        return attributes;
      },
      {}
    );
  },
  {
    cacheKey: ([variant]) =>
      `${variant.product.realId}-${JSON.stringify(variant.options)}`,
  }
);

export const minQuantity = (stockConfig: StockConfig | null) => {
  return stockConfig &&
    stockConfig.quantityIncrement.isEnabled &&
    stockConfig.quantityIncrement.value > stockConfig.minimumSaleQuantity
    ? stockConfig.quantityIncrement.value
    : (stockConfig && stockConfig.minimumSaleQuantity) || 1;
};

export const maxQuantity = (stockConfig: StockConfig | null) => {
  return (
    (stockConfig && Math.min(stockConfig.maximumSaleQuantity, 100)) ||
    DEFAULT_MAX_QUANTITY
  );
};

export const incrementQuantity = (stockConfig: StockConfig | null) => {
  return stockConfig && stockConfig.quantityIncrement.isEnabled
    ? stockConfig.quantityIncrement.value
    : 1;
};

export const getScrollTo = (product: ProductInterface) => {
  const { shop, isGiftVoucher, id } = product;
  /**
   * Because we are using a permanent shop for gift vouchers, the "shop" is being used
   * as the category and therefore doesn't help with the scrollTo for a product, which we
   * require since there are no shops in gift-vouchers listing page.
   */
  return `#${shop?.id ? (isGiftVoucher ? id : shop.id) : id}`;
};

export const catalogPageAnchorLink = (
  product: ProductInterface,
  scrollTo?: string
) => {
  if (product.isClearanceSale) {
    return { href: `/clearance-sale${scrollTo || ''}` };
  }
  if (product.permanentShop?.id && !product.isGiftVoucher) {
    return {
      dynamicUrl: '/shops/[id]',
      href: `/shops/${product.permanentShop.id}${scrollTo || ''}`,
    };
  }
  if (product.isGiftVoucher) {
    return { href: `/${product.permanentShop?.id}${scrollTo || ''}` };
  }
  return { href: `/${scrollTo || ''}` };
};

export const catalogPageBreadcrumbs = (
  product: ProductInterface,
  date?: string
): { pageLink: LinkProps; categoryLink?: LinkProps } => {
  const {
    permanentShop,
    isGiftVoucher,
    isClearanceSale,
    shop,
    id,
    topLevelCategories,
    isLunchtimeProduct,
  } = product;
  const isTodaysDeal = !permanentShop?.id && !isGiftVoucher && !isClearanceSale;

  const label = isClearanceSale
    ? 'Clearance Sale'
    : permanentShop
    ? permanentShop.name
    : "Today's Deals";

  const category = topLevelCategories?.find(c => !!c.id);
  const showCategoryLink = isTodaysDeal && category && !isLunchtimeProduct;
  const scrollTo = getScrollTo(product);

  return {
    pageLink: {
      ...catalogPageAnchorLink(product, scrollTo),
      children: label,
      fontWeight: 'normal',
    },
    categoryLink: showCategoryLink
      ? {
          href: `/category/${category.id}${date ? `/?when=${date}` : ''}`,
          dynamicUrl: '/category/[id]',
          children: asTitleCase(category.name),
          fontWeight: 'normal',
        }
      : undefined,
  };
};
