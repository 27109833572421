import primary, {
  colors as baseColors,
  colorPalette as baseColorPalette,
  gradientConfettiPalette as baseGradientConfettiPalette,
} from './onedayonly';

const colorPalette = {
  ...baseColorPalette,
  extraTimePurple: '#ba1c8b',
};

const colors = {
  ...baseColors,
  headerPrimary: colorPalette.extraTimePurple,
};

const gradientConfettiPalette = {
  ...baseGradientConfettiPalette,
  default: {
    start: '#BA1E8A',
    middle: '#99227C',
    end: '#79276E',
    confetti: '#DB4BB6',
  },
};

const theme = {
  ...primary,
  name: 'extratimedeals',
  colors,
  colorPalette,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;
