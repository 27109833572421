import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = (): [
  (action: string) => Promise<string | undefined>,
  { loading: boolean; hasError: boolean }
] => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const callback = useCallback(
    async (action: string) => {
      setLoading(true);
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha(action);
          if (token) {
            return token;
          }
        } catch (error) {
          setHasError(true);
        } finally {
          setLoading(false);
        }
      }
    },
    [executeRecaptcha]
  );

  return [callback, { loading, hasError }];
};

export default useRecaptcha;
