import { css, Theme } from '@emotion/react';

export const fadeAnimations = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const soldOutWrapperStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
`;

export const clickable = css`
  cursor: pointer;
  line-height: 1;
  padding: 0;
  pointer-events: auto;
  text-align: center;
  white-space: nowrap;
`;

export const styledCms = (
  theme: Theme,
  config?: { highlightedBackground?: boolean }
) => css`
  margin-bottom: ${theme.space[3]}px;
  color: ${theme.colors.black};
  font-size: ${theme.fontWeights['r']};
  line-height: 1.2em !important;
  overflow: hidden;

  *.colour-text {
    color: ${theme.colors.primary};
  }
  a {
    font-weight: bold;
    cursor: pointer;
    color: ${theme.colors.blue};
  }

  ${config?.highlightedBackground &&
  css`
    section:first-of-type {
      background-color: ${theme.colors.lightBlue};

      a {
        color: ${theme.colors.blue};
      }
    }
  `}

  section {
    margin-top: 20px;
    line-height: 1.8em !important;
    ${config?.highlightedBackground &&
    css`
      padding: 15px 20px 10px 20px;
      background-color: ${theme.colors.lightGrey};

      a {
        color: ${theme.colors.darkGrey};
      }
    `}
  }
  img {
    max-width: 100%;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${theme.fontWeights.bold};
    font-family: ${theme.fonts.header};
    margin: 0 0 1em 0;
    line-height: 1.1em !important;
  }

  dl,
  ul {
    list-style-type: initial;
    margin: 1em 0;
    padding-left: ${theme.space[3]}px;
  }

  ol {
    list-style-type: decimal;
    margin: 1em 0;
    padding-left: ${theme.space[3]}px;
  }
  li {
    margin-top: 0.7em !important;
    margin-left: 1em !important;
  }

  dd,
  dt {
    margin: 1em 0;
  }
  p {
    line-height: 1.4em;
    letter-spacing: normal;
  }
`;
