import { css } from '@emotion/react';
import { Flex, Grid } from 'components/box';
import NewsLetterSubscribeForm from 'components/newsletter/subscribe-form';
import dynamic from 'next/dynamic';
import resourceUrl from '../../../../util/make-url';

const Modal = dynamic(
  () => import('components/_shared/widgets/modal/with-close')
);

const NewsletterModal = ({
  close,
  isOpen,
}: {
  close: () => void;
  isOpen: boolean;
}) => (
  <Modal
    close={close}
    isOpen={isOpen}
    iconColor="darkishGrey"
    iconSize={20}
    size="small"
    padding={0}
    removeContentPadding
  >
    <Grid gridTemplateColumns={['1fr', '1fr 1fr', '4fr 3fr']} gridColumnGap={0}>
      <Flex
        px="5px"
        py="14px"
        flexDirection="column"
        borderTopLeftRadius={[0, '8px']}
        borderBottomLeftRadius={[0, '8px']}
        justifyContent="center"
        css={theme => css`
          background-image: linear-gradient(
            to right,
            ${theme.colors.turquoise},
            ${theme.colors.darkTurquoise}
          );
        `}
      >
        <img
          src={resourceUrl('odo/newsletter-subscription-copy.png', {
            width: 600,
            isAssetUrl: true,
          })}
          alt="Sign up to our newsletter and get R59 free shipping on our unbeatable daily deals!"
          css={css`
            width: 100%;
          `}
        />
        <img
          src={resourceUrl('odo/newsletter-subscription-products.png', {
            width: 600,
            isAssetUrl: true,
          })}
          alt="Products - Ts and Cs apply"
          css={css`
            width: 100%;
          `}
        />
      </Flex>

      <NewsLetterSubscribeForm
        gridColumnLayout={{ mobile: '1fr', desktop: '1fr' }}
        subscriptionComplete={close}
        signupLocation="HEADER"
        buttonColor="lime"
        buttonText="Sign me up!"
      />
    </Grid>
  </Modal>
);

export default NewsletterModal;
